








































































































































import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'Footer' })
export default class Footer extends Vue {}

