











import { Vue, Component } from 'vue-property-decorator';
import Header from '@/vue-app/components/Header.vue';
import Footer from '@/vue-app/components/Footer.vue';

@Component({
  name: 'Layout',
  components: {
    Header,
    Footer,
  },
})
export default class Layout extends Vue {}

