
















































import { Component, Vue, Watch } from 'vue-property-decorator';

import HeaderViewModel from '@/vue-app/view-models/components/header-view-model';

@Component({ name: 'Header' })
export default class Header extends Vue {
  header_view_model = Vue.observable(new HeaderViewModel());

  @Watch('header_view_model.header_position')
  onHeaderPositionChange(new_header_position: 'relative' | 'sticky') {
    this.header_view_model.toggleScrollListener(new_header_position);
  }

  created() {
    this.header_view_model.toggleScrollListener(this.header_view_model.header_position);
  }

  destroy() {
    this.header_view_model.removeScrollListenerIfExists();
  }
}

